import Head from "next/head"

export const CanonicalTag = ({href}) => {

    return (
        <Head>
            <link rel="canonical" href={href} />
        </Head>
    )

}