import dynamic from "next/dynamic"
import { useApp } from "./useApp"

const AppBacklogsView = dynamic(() =>
  import('modules/plinzip/views/app/AppBacklogsView').then((mod) => mod.AppBacklogsView)
)

const SearchTasksView = dynamic(() =>
  import('modules/app-gtf/SearchTasksView').then((mod) => mod.SearchTasksView)
)

const OnboardingView = dynamic(() =>
  import('modules/app-gtf/OnboardingView').then((mod) => mod.OnboardingView)
)

const scaffolds = {
    PlinzipWorkspaceScreenScaffold: dynamic(() => import('modules/plinzip/scaffold/PlinzipWorkspaceScreenScaffold').then((mod) => mod.PlinzipWorkspaceScreenScaffold)),
    ReleaseWriterWorkspaceScreenScaffold: dynamic(() => import('modules/app-releasewriter/ReleaseWriterWorkspaceScreenScaffold').then((mod) => mod.ReleaseWriterWorkspaceScreenScaffold)),
    WorkspaceScreenScaffoldExtended: dynamic(() => import('components/layout/screen/WorkspaceScreenScaffoldExtended').then((mod) => mod.WorkspaceScreenScaffoldExtended)),
}

const customTabs = {
    plinzip: [
        {
            title: 'Rankings',
            key: 'rankings',
            content: ()=><AppBacklogsView />
        },
    ],
    gtf: [
        {
            title: 'Search Tasks',
            key: 'stks',
            content: ()=><SearchTasksView />
        },
        {
            title: 'Onboarding',
            key: 'onboarding',
            content: ()=><OnboardingView />
        },
    ]
}

const appComponentRenderer = {
    plinzip: dynamic(() => import('modules/plinzip/scaffold/PrioneerRenderer').then((mod) => mod.PrioneerRenderer)),
    rankp: dynamic(() => import('modules/plinzip/scaffold/PrioneerRenderer').then((mod) => mod.PrioneerRenderer)),
}

export const useAppComponents = () => {

    const { app } = useApp()

    const key = app.workspaceScreenScaffold || 'WorkspaceScreenScaffoldExtended'

    return {
        WorkspaceScreenScaffold: scaffolds[key], //never null
        appOverviewCustomTabs: customTabs[app.key],
        AppComponentRenderer: appComponentRenderer[app.key],
    }

}