import { AppProvider } from 'modules/yoio/useApp';
import { MuiThemeFromApp } from 'modules/yoio/useApp';
import { DefaultAppScaffold } from 'modules/yoio/DefaultAppScaffold';
import { LandingPageProvider } from 'modules/landingPage/LandingPageContext';
import { YoioContextProvider } from 'modules/yoio/useYoioContext';
import { MemoizedPageHead } from 'modules/yoio/component/PageHead';
import { NoIndexNoFollow } from 'components/seo/NoIndexNoFollow';
import { CanonicalTag } from 'components/seo/CanonicalTag';
import { PageNavigateStateProvider } from 'modules/picasso-ui/menu/PageNavigateState';
import { CspContextProvider } from 'modules/yoio-modules/csp/useCsp';
import dynamic from 'next/dynamic';

//load dynamically modules that are only needed in app workspace but not on landing pages
const ConfirmationDialogProvider = dynamic(() =>
  import('components/dialog/confirm/ConfirmationDialog').then((mod) => mod.ConfirmationDialogProvider)
)
const FormDialogProvider = dynamic(() =>
  import('components/dialog/confirm/FormDialog').then((mod) => mod.FormDialogProvider)
)
const WallDialogProvider = dynamic(() =>
  import('components/dialog/confirm/WallDialog').then((mod) => mod.WallDialogProvider)
)
const AppProgressIndicator = dynamic(() =>
  import('modules/picasso-ui/main/AppProgressIndicator').then((mod) => mod.AppProgressIndicator)
)
const AppAlertsSnackbar = dynamic(() =>
  import('modules/picasso-ui/main/AppAlertsSnackbar')
)
const AppAlertsPlanUpgrade = dynamic(() =>
  import('modules/picasso-ui/main/AppAlertsPlanUpgrade')
)

export const YoioApp = ({children, nonce, initialApp, yoioPageSettings, landingPage, yoioPageMeta, userAgent}) => {

    const renderInner = () => {

       const yoioContext = {pageSettings: yoioPageSettings}

       if (userAgent) {
        yoioContext.userAgent = userAgent
       }
       if (yoioPageMeta) {
        yoioContext.pageMeta = yoioPageMeta
       }

        return (
            <YoioContextProvider value={yoioContext}>
              <LandingPageProvider landingPage={landingPage}>
                <MemoizedPageHead />
    
                <PageNavigateStateProvider>
    
                  <DefaultAppScaffold>
                    {children}
                    <AppAlertsPlanUpgrade />
                  </DefaultAppScaffold>
    
                </PageNavigateStateProvider>
              </LandingPageProvider>
            </YoioContextProvider>
          )
      }

      const isLandingPage = landingPage !== undefined && landingPage !== null

      return (
        <>
            <CspContextProvider nonce={nonce}>
                {yoioPageSettings?.noindex && <NoIndexNoFollow />}
                {yoioPageSettings?.canonicalUrl && <CanonicalTag href={yoioPageSettings.canonicalUrl} />}
                <AppProvider initial={initialApp} loadAppEnabled={yoioPageSettings?.loadAppEnabled}>
                    
                    <MuiThemeFromApp>
                        <>
                        {isLandingPage ? 
                            <>{renderInner()} {landingPage.hasForms && <AppAlertsSnackbar />}</>
                            :
                            <>
                            <FormDialogProvider>
                                <ConfirmationDialogProvider>
                                <WallDialogProvider>
                                    {renderInner()}
                                </WallDialogProvider>
                                </ConfirmationDialogProvider>
                            </FormDialogProvider>

                            <AppAlertsSnackbar />
                            <AppProgressIndicator />
                            </>
                        }

                        </>
                    </MuiThemeFromApp>

                </AppProvider>
            </CspContextProvider>
        </>
    )

}