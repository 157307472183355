import { createContext, useContext } from "react"

export const CspContext = createContext(null)

export const CspContextProvider = ({children, nonce}) => {

    const nonceEffective = typeof window !== 'undefined' ? document.querySelector('meta[name="yoio-csp-nonce"]')?.content : nonce

    return <CspContext.Provider value={{nonce: nonceEffective}}>{children}</CspContext.Provider>
}

export const useCsp = () => useContext(CspContext)