import { AnalyticsTracking } from "modules/yoio/analytics/AnalyticsTracking";
import { ConditionalWrapper } from "modules/picasso-ui/ConditionalWrapper";
import { MeContext, MeContextProvider } from "modules/react-auth/MeContextProvider";
import { useApp } from "./useApp";
import { useYoioContext } from "./useYoioContext";
import { ErrorBoundary } from "components/error/ErrorBoundary";
import { AppLoadingState } from "./component/AppLoadingState";
import { useAppComponents } from "./useAppComponents";
import { AccessCredentialExpiredDialog } from "./access/AccessCredentialExpiredDialog";
import { isNullOrUndefined } from "utils/objectUtils";
import { useIsExpired } from "modules/react-auth/credentialExpiry";
import { useContext, useEffect } from "react";
import { useErrorNotify } from "./errorsService";
import { PageType } from "modules/yoio-modules/core/PageTypes";
import dynamic from "next/dynamic";
import { NotificationsLoader } from "modules/yoio-modules/notifications/useNotifications";

//no idea what usecase this was required for
{/*             <Head>
                <script src="https://apis.google.com/js/api.js" />
            </Head> */}

const NotificationsDisplayWidget = dynamic(() =>
    import('modules/yoio-modules/notifications/useNotifications').then((mod) => mod.NotificationsDisplayWidget)
)
const RefreshOnNewVersion = dynamic(() =>
    import('modules/yoio-modules/core/RefreshOnNewVersion').then((mod) => mod.RefreshOnNewVersion)
)

export const DefaultAppScaffold = ({children}) => {

    const { app } = useApp()

    const yoioContext = useYoioContext()

    let { loadMe, loadMeLazy, signInAllowed, signInForce, pageType, isWorkspaceMember, analyticsDisabled, dontDisplayWorkspaceScaffold, screenHeightContent } = yoioContext.pageSettings

    return (
        <>
            {app && (
                <ConditionalWrapper
                    condition={loadMe}
                    wrapper={children => 
                        <MeContextProvider 
                            lazy={loadMeLazy}
                            loadingElement={<AppLoadingState />}
                            signInAllowed={signInAllowed}
                            signInForce={signInForce}
                        >
                            {children}
                        </MeContextProvider>
                    }
                >
                    {analyticsDisabled !== true &&
                        <ErrorBoundary hidden={true}>
                            <AnalyticsTracking />
                        </ErrorBoundary>
                    }
                    {/**
                     * isWorkspaceMember: sharing situation - when sharing a workspace page with external people,
                     * then you must predermine (ideally in getServerSideProps) if the user is a workspace member.
                     * 
                     * If he is none, then the PageType will not render the scaffold
                     * 
                     * For compatibility with old pages, null is considered as 'workspace member' by default
                     */}
                    {pageType === PageType.workspace 
                        ? <> 
                            { (isWorkspaceMember !== false && dontDisplayWorkspaceScaffold !== true)
                                ? <WorkspacePageTypeComponents screenHeightContent={screenHeightContent}>{children}</WorkspacePageTypeComponents>
                                : children
                            }
                            <RefreshOnNewVersion />
                         </>
                        : children
                    }

                    {(pageType === PageType.workspace || pageType === PageType.service) && <AppPrimaryNotifications />}

                    {(isNullOrUndefined(pageType) || pageType === PageType.workspace || pageType === PageType.service) && 
                        <AccessCredentialExpiredDialogIfExpired />
                    }
                </ConditionalWrapper>
            )}
        </>
    )

}

/**
 * Connection for notifications that should display inapp onscreen.
 */
const AppPrimaryNotifications = () => {

    const meContextVal = useContext(MeContext);

    const userId = meContextVal?.me?.accessContext?.accountId
    if (!userId) {
        return null
    }

    return <><ErrorBoundary><NotificationsLoader userId={userId} targetChannel="app-primary" /><NotificationsDisplayWidget channel="app-primary" /></ErrorBoundary></>
}

const AccessCredentialExpiredDialogIfExpired = () => {

    const expired = useIsExpired()

    const { notifyInfo } = useErrorNotify()

    useEffect(()=>{
        if (expired === true) {
            notifyInfo('showing "Please login again" to user because refresh token expired.')
        }
    },[expired])

    if (!expired) {
        return null
    }

    return <AccessCredentialExpiredDialog open={true} />

}

const WorkspacePageTypeComponents = ({children, screenHeightContent}) => {

    const { WorkspaceScreenScaffold } = useAppComponents()

    return (
        <WorkspaceScreenScaffold screenHeightContent={screenHeightContent}>
            {children}
        </WorkspaceScreenScaffold>
    )

}