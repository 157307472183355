
import Head from 'next/head';

export const FavIcon = ({favIconSrc}) => {

    /**
     * To avoid duplicate tags in your head you can use the key property, which will make sure the tag is only rendered once
     */
    return (
        <Head>
            <link rel="icon" href={favIconSrc} key="favicon"/>
        </Head>
    )

}