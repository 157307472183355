import Head from "next/head";

/**
 * 
 * Generates OgImage From beyondCo api call
 * 
 * @param {*} param0 
 * @returns 
 */
export const OgImageFromBeyondCo = ({title, description}) => {
   if (!title) {
    throw new Error("title required")
   }
   if (!description) {
    throw new Error("description required")
   }

    const buildUrl = (title, description) => {
        const urlTemplate = 'https://og.beyondco.de/{title}.png?theme=light&md=1&showLogo=0&body={description}' + 
            '&fontSize=150px&isPost=0&author=The+Cat' +
            '&authorAvatar=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1505776777247-d26acc0e505b%3Fixid%3DMnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8%26ixlib%3Drb-1.2.1%26auto%3Dformat%26fit%3Dcrop%26w%3D800%26q%3D80' +
            '&postUrl=&readDuration=5+minute+read';
         let url = urlTemplate;
         url = url.replace('{title}', encodeURIComponent(title))
         url = url.replace('{description}', encodeURIComponent(description))
         return url;
    }

    const url = buildUrl(title, description);

    return (
          <Head>
            <meta property="og:image" content={url} />
          </Head>
    )

}