import { useRouter } from "next/router";
import { createContext, useContext } from "react";
import { isTimestampMillisOlderThanSeconds } from "utils/dateUtils";
import { isHavingValue, notNullNotUndefined } from "utils/objectUtils";

const PageNavigateStateContext = createContext({})

let cache = new Map()

export const PageNavigateStateProvider = ({children}) => {

    const router = useRouter()

    const setData = (path, data) => {
        notNullNotUndefined(path)

        if (data) {
            data._cachedAt = new Date().getTime()
        }

        cache.set(path, data)
    }

    const getData = () => {
        const searchKey = router.asPath
        if (searchKey){
            const cacheEntry = cache.get(searchKey)
            if (isHavingValue(cacheEntry) && !isTimestampMillisOlderThanSeconds(cacheEntry._cachedAt, 25)) {
                return cacheEntry
            }
        }
        return null
    }

    return <PageNavigateStateContext.Provider value={{ setData, getData }}>{children}</PageNavigateStateContext.Provider>
}

export const usePageNavigateState = () => {

    const pagedata = useContext(PageNavigateStateContext).getData()

    return pagedata
    
}

export const usePageNavigateStateRouter = () => {
    
    const context = useContext(PageNavigateStateContext)
    
    const router = useRouter()

    const push = (path, data) => {
        notNullNotUndefined(path)

        if (data) {
            context.setData(path, data)
        }
       
        return router.push(path)
    }

    return {...context, push}
}