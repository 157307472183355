import { Box, Button, Typography } from "@mui/material"
import { SimpleDialog } from "components/dialog/SimpleDialog"
import { clearAuthenticatedUser, clearLastAuthenticatedUser } from "modules/react-auth/currentUser"
import { useRouter } from "next/router"
import { appRoutes } from "utils/appRoutes"

export const AccessCredentialExpiredDialog = ({open}) => {

    const router = useRouter()

    const handleClick = () => {
        clearAuthenticatedUser()
        clearLastAuthenticatedUser()
        router.push(appRoutes.yoio.access.login.buildPath())
    }

    return (
        <SimpleDialog open={open} title="Please login again" message="test" fullWidth maxWidth="xs" titleTextAlign="center" closeForbidden hideActions>
            <Box color="text.secondary" textAlign="center" pb={3}>
                <Typography>
                  Long inactivity. To keep your data secure, your session has timed out.
                </Typography>
            </Box>
            <Button fullWidth variant="contained" color="primary" size="large" onClick={handleClick}>Login</Button>
        </SimpleDialog>
    )

}