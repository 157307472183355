import Head from "next/head"

export const PageHeadMetaProperties = ({title, description}:{title?:string, description?: string}) => {

    return (
        <Head>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description}/>}
        </Head>
    )

}