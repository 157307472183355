import { useWindowEvent } from "modules/picasso-ui/state/useWindowEvent"
import { AccessEvent, IssuerId } from "modules/yoio/YoioTypes"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { refreshAccessToken, useSignedIn } from "."
import { getAuthenticatedUser, getLastAuthenticatedUser } from "./currentUser"

export const useIsExpired = () => {

    const router = useRouter()

    const [expired, setExpired] = useState(false)

    const { me } = useSignedIn()

    /**
     * Functions
     */

    const isReLoginRequired = () => {
        const lastAuthUser = getLastAuthenticatedUser()
        if (lastAuthUser != null && lastAuthUser?.credential.isserId === IssuerId.YOIO) {
            if (getAuthenticatedUser() == null) {
                return true
            }
        }
        return false
    }

    const refreshAccessTokenIfNecessary = () => {
        if (isReLoginRequired()) {

            return refreshAccessToken().then(()=>{
                if (isReLoginRequired()) {
                    setExpired(true)
                }
            })
            .catch((error)=>{
                console.error(error)
                setExpired(true)
            })
            
        }

        return Promise.resolve()
    }

    /**
     * Events
     */

    // If notifies, e.g., by axios error handler
    useWindowEvent(AccessEvent.userLoginRequired, ()=> {
        console.debug('useIsExpired')
        setExpired(true)
    })

     // If 'user navigates page'
    const handleRouteChange = async (url) => {
        if (url === '/login') {
            // ignore
            return;
        }
        if (url === '/logout') {
            // ignore
            return;
        }
        if (isReLoginRequired()) {

            try {
                await refreshAccessToken()
            } catch (error) {
                console.error(error)
                setExpired(true)
                return;
            }

            if (isReLoginRequired()) {
                console.error('Abort route change')
                Router.events.emit('routeChangeError');
                Router.replace(Router, Router.asPath, { shallow: true })
                setExpired(true)
                throw 'Abort route change. Please ignore this error.';
            }
        }
    }
    
    useEffect(() => {
        try {
          router.events.on('routeChangeStart', handleRouteChange)
    
          return () => {
            router.events.off('routeChangeStart', handleRouteChange)
          }
        } catch (error) {
          console.error(error)
        }
    }, [router]);

    /**
     * Render
     */

    return expired
} 