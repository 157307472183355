import { FavIcon } from "modules/picasso-ui/scaffold/FavIcon";
import { memo } from "react"
import { PageHeadMetaOgProperties } from "./pageBasics/PageHeadMetaOgProperties";
import { useOgImageData, usePageMeta } from "../usePageMeta";
import { useErrorNotify } from "../errorsService";
import { OgImageFromBeyondCo } from "modules/picasso-ui/scaffold/OgImageFromBeyondCo";
import { PageHeadMetaProperties } from "./pageBasics/PageHeadMetaProperties";

export const PageHead = () => {

    const { notify } = useErrorNotify()

    const { pageTitle, pageDescription, favIconSrc, headMetaDefaultDisabled } = usePageMeta()

    const ogImageData = useOgImageData()

    if (ogImageData?.type === 'generate') {
        if (!ogImageData.title) {
            notify(new Error('cannot include autogenerate og image url. title required.'))
            return null;
        }

        if (!ogImageData.description) {
            notify(new Error('cannot include autogenerate og image url. description required.'))
            return null;
        }
    }

    return (
        <>
            {headMetaDefaultDisabled !== true &&
                <>
                    <PageHeadMetaProperties title={pageTitle} description={pageDescription}/>
                    <PageHeadMetaOgProperties title={pageTitle} description={pageDescription}/>
                    {ogImageData?.type === 'generate' && <OgImageFromBeyondCo {...ogImageData}/>}
                    {ogImageData?.type === 'url' && <PageHeadMetaOgProperties imageUrl={ogImageData.imageUrl} imageUrlTwitter={ogImageData.imageUrlTwitter} />}
                </>
            }
            {favIconSrc && <FavIcon favIconSrc={favIconSrc} />}
        </>
    )

}

export const MemoizedPageHead = memo(PageHead);