
import { AppContext } from 'modules/yoio/useApp';
import { useAnalytics } from 'modules/yoio/analytics/useAnalytics';
import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
//import Head from 'next/head';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_APP_GA_MEASUREMENT_ID

export const AnalyticsTracking = () => {

    const { app } = useContext(AppContext)
    const { trackPageViewed } = useAnalytics(app);

    const router = useRouter();

    useEffect(() => {
      trackPageViewed();
    }, []);

    const handleRouteChanged = () => {
      trackPageViewed();
    }
  
    useEffect(() => {
      try {
        router.events.on('routeChangeComplete', handleRouteChanged)
  
        return () => {
          router.events.off('routeChangeComplete', handleRouteChanged)
        }
      } catch (error) {
        console.error(error)
      }
    }, [router]);


    return (
    <>{/* Global Site Tag (gtag.js) - Google Analytics */}
{/*         <Head>
            {trackingEnabled === true && (
          <>
            
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                      gtag('config', '${GA_TRACKING_ID}', {
                        page_path: window.location.pathname,
                      });
                    `,
              }}
            />
          </>
        )}
      </Head> */}
    </>
    );

}