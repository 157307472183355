import Head from 'next/head';

export const NoIndexNoFollow = () => {

    return (
    <Head>
        <meta name="robots" content="noindex,follow" />
        <meta name="googlebot" content="noindex,follow" />
    </Head>
    );
}