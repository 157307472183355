import Head from "next/head"
import { FunctionComponent } from "react"

export interface PageHeadMetaOgPropertiesProps {
    title?: string
    description?: string
    type?: string
    imageUrl?: string
    imageUrlTwitter?: string
}

export const PageHeadMetaOgProperties: FunctionComponent<PageHeadMetaOgPropertiesProps> = ({title, description, type, imageUrl, imageUrlTwitter}) => {

    const imageUrlTwitterEffective = imageUrlTwitter || imageUrl

    return (
        <Head>
            {title && <meta property="og:title" content={title} />}
            {title && <meta property='twitter:title' content={title}/> }
            {description && <meta property="og:description" content={description} />}
            {type && <meta property="og:type" content={type} />}
            {imageUrl && <meta property="og:image" content={imageUrl} />}
            {imageUrlTwitterEffective && <meta property='twitter:image' content={imageUrlTwitterEffective}/>}
            {imageUrlTwitterEffective && <meta name="twitter:card" content="summary_large_image"/>}
        </Head>
    )
    
}