import { Box, Typography } from '@mui/material';
import { notify } from 'modules/yoio/errorsService';
import { Component, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { VerticalAlignBox } from 'modules/picasso-ui/layout/VerticalAlignBox';

export class ErrorBoundary extends Component {
   
  constructor(props) {
      super(props);
      this.state = { hasError: false, hidden: props.hidden, control: props.control };
    }
  
    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error) { //error, errorInfo
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, errorInfo);
      notify(error)
    }
  
    componentWillReceiveProps = (nextProps) => {
      if (nextProps.control && this.state.control && this.state.hasError && nextProps.control.version !== this.state.control.version) {
        this.setState({
          ...this.state,
          hasError: false
        })
      }
    }

    render() {
      if (this.state.hasError) {
        if (this.state.hidden === true) {
          return <></>
        }

        let variant = this.props.variant

        variant = variant || 'view'

        // You can render any custom fallback UI
        return (
          <>
            {/** for large areas of content  */}
            {variant === 'view' &&
              <div>
                <Box mb={2}>
                    <Typography sx={{fontSize: '1.5rem'}}>Something has gone wrong.</Typography>
                  </Box>
                <Typography>Our team has been notified about the error.</Typography>
              </div>
            }
            {variant === 'inline' &&
              <VerticalAlignBox display="inline-block">
                <ErrorOutlineIcon sx={{fontSize: '14px'}} /><Typography sx={{fontSize: '11px'}}>{this.props.message || 'failed to load settings'}</Typography>
              </VerticalAlignBox>
            }
          </>
        )
      }
  
      return this.props.children || <></>; 
    }
  }

  export const useErrorBoundaryControl = () => {

    const [version, setVersion] = useState(0)

    const errorBoundaryProps = {
      control: {
        version
      }
    }
    
    const reset = () => {
      setVersion(c=>++c)
    }

    return { reset, errorBoundaryProps }
  }